.App {
    text-align: center;
  }
  
  button {
    margin: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  table {
    margin: auto;
    width: 80%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #ddd;
  }
  